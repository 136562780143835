import Money from "../../helpers/utils/money/money";
import { SEX_LABEL } from "../../types/sex";

function getLocaoHour(hour: number, difference: number) {
  let startHour = 0;
  let endHour = 23;

  let result = hour + difference;
  if (result < startHour)
    return endHour + result + 1
  if (result > endHour)
    return result - endHour
  return result
}

export default function computePorHora(data: any, difference: number) {
  if (!data) return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  const array: any[] = [];
  Object.keys(data).forEach((key) => {
    array[getLocaoHour(Number(key), difference)] = Money.centsToCoin(data[key])
  })
  return array
}

export function computeSexoPorHora(data: any, difference: number, isqtd: boolean) {
  const result: any = {
    indefinido: Array(24).fill(0),
    masculino: Array(24).fill(0),
    feminino: Array(24).fill(0),
  };

  if (!data) return result;

  data.forEach((item: any) => {
    const { sex, hour, qtd,price } = item;
    const hourIndex = Number(hour);
    const sex_pt_br = SEX_LABEL[Number(sex)]?.toLowerCase()
    if (hourIndex >= 0 && hourIndex < 24 && result[sex_pt_br]) {
      if (isqtd) {
        result[sex_pt_br][hourIndex] += qtd;
      } else {
        result[sex_pt_br][hourIndex] += Money.centsToCoin(price);
      }
    }
    
  });

  return {
    arrayIndefinido: result.indefinido,
    arrayMasculino: result.masculino,
    arrayFeminino: result.feminino,
  };
}




