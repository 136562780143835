import React, { useState, useEffect, useContext, memo, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import Input from '../../bootstrap/forms/Input';
import Label from '../../bootstrap/forms/Label';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { DefaultContext } from '../../../contexts/default';
import PreAlert from '../../../helpers/utils/preAlert';
import Button from '../../bootstrap/Button';
import { inputMoneyMask, inputOnlyNumbers, inputOnlyNumbersNoStitch } from '../../../utils/input';
import Relatorio from './Relatorio';
import NovoRelatorio from './NovoRelatorio';
import EventGeralDB from '../../../database/wrappers/eventGeral';
import ClosureResourcesEventDB from '../../../database/wrappers/closureResourceEvent';
import BtnLoad from '../../MyCustom/BtnLoad';
import BtnConfirm from '../../MyCustom/BtnConfirm';
import masks, { unmask } from '../../../helpers/utils/masks';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks from '../../bootstrap/forms/Checks';
import Nav, { NavItem } from '../../bootstrap/Nav';
import { ADQUIRENTE, ADQUIRENTE_LABEL } from '../../../types/adquirante';
import Select from '../../bootstrap/forms/Select';
import { TYPE_OF_CHARGE, TYPE_OF_CHARGE_LABEL } from '../../../types/charge';




const ModalClosure = ({ open, setIsOpen, setIsClose, eventSelected }) => {

  const { estabSelected, onShowAlert } = useContext(DefaultContext);

  const [eventGeral, seteventGeral] = useState(null);
  const [resourceRegistered, setresourceRegistered] = useState(false);
  const [openRelatorio, setOpenRelatorio] = useState(false);
  const [openNovoRelatorio, setOpenNovoRelatorio] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabSelected, settabSelected] = useState(0);

  useEffect(() => {
    if (!open) {
      seteventGeral(null)
      setresourceRegistered(false)
      return formik.resetForm();
    }
    if (eventSelected) {
      new EventGeralDB(estabSelected)
        .get(eventSelected.id)
        .then(data => {
          if (data)
            seteventGeral(data)
        })
        .catch(error => {
          console.error(error)
        })

      new ClosureResourcesEventDB(estabSelected)
        .get(eventSelected.id)
        .then(data => {
          if (!data) return
          const {
            returned_resources,
            internet,
            technical,
            acquirerReversal,
            acquirerReversalDebit = 0,
            adquirente,
            isAdvanceCredit,
            typeCharge
          } = data;


          formik.setValues({
            res_returned_pos: returned_resources?.pos.toString(),
            res_returned_pos_backup: returned_resources?.pos_backup.toString(),
            res_returned_totems: returned_resources?.totems.toString(),
            res_returned_totems_recharges: returned_resources?.totems_recharges.toString(),
            res_returned_totems_cables: returned_resources?.totems_cables.toString(),
            res_returned_totems_cuffs: returned_resources?.totems_cuffs.toString(),
            res_returned_totems_pedestal: returned_resources?.totems_pedestal.toString(),
            res_returned_totems_cover: returned_resources?.totems_cover.toString(),
            res_returned_cards: returned_resources?.cards.toString(),
            res_returned_cards_adm: returned_resources?.cards_adm ? returned_resources?.cards_adm.toString() : "",
            res_returned_bracelets: returned_resources?.bracelets.toString(),
            res_returned_bobines: returned_resources?.bobines.toString(),
            res_returned_extension: returned_resources?.extension.toString(),
            res_returned_ruler: returned_resources?.ruler.toString(),
            res_returned_kit_cordao: returned_resources?.kit_cordao.toString(),
            res_returned_pouch: returned_resources?.pouch.toString(),
            res_returned_tablet: returned_resources?.tablet.toString(),
            res_returned_tablet_power: returned_resources?.tablet_power.toString(),
            res_returned_pos_power: returned_resources?.pos_power.toString(),
            res_returned_transport_box: returned_resources?.transport_box.toString(),
            res_returned_processor_wifi: returned_resources?.processor_wifi.toString(),
            res_returned_tripod_wifi: returned_resources?.tripod_wifi.toString(),
            res_returned_router_wifi: returned_resources?.router_wifi.toString(),
            res_returned_cable_lan: returned_resources?.cable_lan.toString(),
            internet_wifi: masks.money(internet.wifi.toString()),
            internet_satellite: masks.money(internet.satellite.toString()),
            // internet_system_wifi: masks.money(internet.system_wifi.toString()),

            technical_daily: technical.daily.toString(),
            technical_extra_hour: technical.extra_hour.toString(),
            technical_freight: masks.money(technical.freight.toString()),
            technical_ticket: masks.money(technical.ticket.toString()),
            technical_uber: masks.money(technical.uber.toString()),

            technical_accommodation: masks.money(technical.accommodation.toString()),
            technical_food: masks.money(technical.food.toString()),

            acquirerReversal: masks.money(acquirerReversal.toString()),
            acquirerReversalDebit: masks.money(acquirerReversalDebit.toString()),
            adquirente,
            isAdvanceCredit,
            typeCharge: typeCharge ? Number(typeCharge) : TYPE_OF_CHARGE.AUTOMATIC_CHARGE,
          })

          setresourceRegistered(true)
        })
        .catch(error => console.error(error))
    }
  }, [eventSelected, open])

  const validate = useCallback((values) => {
    const errors = {};

    if (!values.res_returned_pos) {
      errors.res_returned_pos = 'Valor inválido'
    }
    if (eventGeral?.resources?.pos < values.res_returned_pos) {
      errors.res_returned_pos = 'Valor inválido'
    }
    if (!values.res_returned_pos_backup) {
      errors.res_returned_pos_backup = 'Valor inválido'
    }
    if (eventGeral?.resources?.pos_backup < values.res_returned_pos_backup) {
      errors.res_returned_pos_backup = 'Valor inválido'
    }
    if (!values.res_returned_totems) {
      errors.res_returned_totems = 'Valor inválido'
    }
    if (eventGeral?.resources?.totems < values.res_returned_totems) {
      errors.res_returned_totems = 'Valor inválido'
    }
    if (!values.res_returned_totems_recharges) {
      errors.res_returned_totems_recharges = 'Valor inválido'
    }
    if (eventGeral?.resources?.totems_recharges < values.res_returned_totems_recharges) {
      errors.res_returned_totems_recharges = 'Valor inválido'
    }
    if (!values.res_returned_totems_cables) {
      errors.res_returned_totems_cables = 'Valor inválido'
    }
    if (eventGeral?.resources?.totems_cables < values.res_returned_totems_cables) {
      errors.res_returned_totems_cables = 'Valor inválido'
    }
    if (!values.res_returned_totems_cuffs) {
      errors.res_returned_totems_cuffs = 'Valor inválido'
    }
    if (eventGeral?.resources?.totems_cuffs < values.res_returned_totems_cuffs) {
      errors.res_returned_totems_cuffs = 'Valor inválido'
    }
    if (!values.res_returned_totems_pedestal) {
      errors.res_returned_totems_pedestal = 'Valor inválido'
    }
    if (eventGeral?.resources?.totems_pedestal < values.res_returned_totems_pedestal) {
      errors.res_returned_totems_pedestal = 'Valor inválido'
    }
    if (!values.res_returned_totems_cover) {
      errors.res_returned_totems_cover = 'Valor inválido'
    }
    if (eventGeral?.resources?.totems_cover < values.res_returned_totems_cover) {
      errors.res_returned_totems_cover = 'Valor inválido'
    }
    if (!values.res_returned_cards) {
      errors.res_returned_cards = 'Valor inválido'
    }
    if (eventGeral?.resources?.cards < values.res_returned_cards) {
      errors.res_returned_cards = 'Valor inválido'
    }
    if (!values.res_returned_cards_adm) {
      errors.res_returned_cards_adm = 'Valor inválido'
    }
    if (eventGeral?.resources?.cards_adm < values.res_returned_cards_adm) {
      errors.res_returned_cards_adm = 'Valor inválido'
    }
    if (!values.res_returned_bracelets) {
      errors.res_returned_bracelets = 'Valor inválido'
    }
    if (eventGeral?.resources?.bracelets < values.res_returned_bracelets) {
      errors.res_returned_bracelets = 'Valor inválido'
    }
    if (!values.res_returned_bobines) {
      errors.res_returned_bobines = 'Valor inválido'
    }
    if (eventGeral?.resources?.bobines < values.res_returned_bobines) {
      errors.res_returned_bobines = 'Valor inválido'
    }
    if (!values.res_returned_extension) {
      errors.res_returned_extension = 'Valor inválido'
    }
    if (eventGeral?.resources?.extension < values.res_returned_extension) {
      errors.res_returned_extension = 'Valor inválido'
    }
    if (!values.res_returned_ruler) {
      errors.res_returned_ruler = 'Valor inválido'
    }
    if (eventGeral?.resources?.ruler < values.res_returned_ruler) {
      errors.res_returned_ruler = 'Valor inválido'
    }
    if (!values.res_returned_kit_cordao) {
      errors.res_returned_kit_cordao = 'Valor inválido'
    }
    if (eventGeral?.resources?.kit_cordao < values.res_returned_kit_cordao) {
      errors.res_returned_kit_cordao = 'Valor inválido'
    }
    if (!values.res_returned_pouch) {
      errors.res_returned_pouch = 'Valor inválido'
    }
    if (eventGeral?.resources?.pouch < values.res_returned_pouch) {
      errors.res_returned_pouch = 'Valor inválido'
    }
    if (!values.res_returned_tablet) {
      errors.res_returned_tablet = 'Valor inválido'
    }
    if (eventGeral?.resources?.tablet < values.res_returned_tablet) {
      errors.res_returned_tablet = 'Valor inválido'
    }
    if (!values.res_returned_tablet_power) {
      errors.res_returned_tablet_power = 'Valor inválido'
    }
    if (eventGeral?.resources?.tablet_power < values.res_returned_tablet_power) {
      errors.res_returned_tablet_power = 'Valor inválido'
    }
    if (!values.res_returned_pos_power) {
      errors.res_returned_pos_power = 'Valor inválido'
    }
    if (eventGeral?.resources?.pos_power < values.res_returned_pos_power) {
      errors.res_returned_pos_power = 'Valor inválido'
    }
    if (!values.res_returned_transport_box) {
      errors.res_returned_transport_box = 'Valor inválido'
    }
    if (eventGeral?.resources?.transport_box < values.res_returned_transport_box) {
      errors.res_returned_transport_box = 'Valor inválido'
    }
    if (!values.res_returned_processor_wifi) {
      errors.res_returned_processor_wifi = 'Valor inválido'
    }
    if (eventGeral?.resources?.processor_wifi < values.res_returned_processor_wifi) {
      errors.res_returned_processor_wifi = 'Valor inválido'
    }
    if (!values.res_returned_tripod_wifi) {
      errors.res_returned_tripod_wifi = 'Valor inválido'
    }
    if (eventGeral?.resources?.tripod_wifi < values.res_returned_tripod_wifi) {
      errors.res_returned_tripod_wifi = 'Valor inválido'
    }
    if (!values.res_returned_router_wifi) {
      errors.res_returned_router_wifi = 'Valor inválido'
    }
    if (eventGeral?.resources?.router_wifi < values.res_returned_router_wifi) {
      errors.res_returned_router_wifi = 'Valor inválido'
    }
    if (!values.res_returned_cable_lan) {
      errors.res_returned_cable_lan = 'Valor inválido'
    }
    if (eventGeral?.resources?.cable_lan < values.res_returned_cable_lan) {
      errors.res_returned_cable_lan = 'Valor inválido'
    }

    if (!values.internet_wifi) {
      errors.internet_wifi = 'Valor inválido'
    }
    if (!values.internet_satellite) {
      errors.internet_satellite = 'Valor inválido'
    }
    // if(!values.internet_system_wifi){
    //   errors.internet_system_wifi = 'Valor inválido'
    // }
    if (!values.technical_daily) {
      errors.technical_daily = 'Valor inválido'
    }
    if (!values.technical_extra_hour) {
      errors.technical_extra_hour = 'Valor inválido'
    }
    if (!values.technical_freight) {
      errors.technical_freight = 'Valor inválido'
    }
    if (!values.technical_ticket) {
      errors.technical_ticket = 'Valor inválido'
    }
    if (!values.technical_uber) {
      errors.technical_uber = 'Valor inválido'
    }
    if (!values.technical_accommodation) {
      errors.technical_accommodation = 'Valor inválido'
    }
    if (!values.technical_food) {
      errors.technical_food = 'Valor inválido'
    }
    if (!values.acquirerReversal) {
      errors.acquirerReversal = 'Valor inválido'
    }
    if (!values.acquirerReversalDebit) {
      errors.acquirerReversalDebit = 'Valor inválido'
    }

    return errors;
  }, [
    eventGeral?.resources?.pos, eventGeral?.resources?.bobines, eventGeral?.resources?.bracelets,
    eventGeral?.resources?.cable_lan, eventGeral?.resources?.cards, eventGeral?.resources?.cards_adm,
    eventGeral?.resources?.extension, eventGeral?.resources?.kit_cordao, eventGeral?.resources?.pos_backup,
    eventGeral?.resources?.pos_power, eventGeral?.resources?.pouch, eventGeral?.resources?.processor_wifi,
    eventGeral?.resources?.router_wifi, eventGeral?.resources?.ruler, eventGeral?.resources?.tablet,
    eventGeral?.resources?.tablet_power, eventGeral?.resources?.totems, eventGeral?.resources?.totems_cables,
    eventGeral?.resources?.totems_cover, eventGeral?.resources?.totems_cuffs, eventGeral?.resources?.totems_pedestal,
    eventGeral?.resources?.totems_recharges, eventGeral?.resources?.transport_box, eventGeral?.resources?.tripod_wifi
  ])

  const formik = useFormik({
    initialValues: {
      res_returned_pos: '',
      res_returned_pos_backup: '',
      res_returned_totems: '',
      res_returned_totems_recharges: '',
      res_returned_totems_cables: '',
      res_returned_totems_cuffs: '',
      res_returned_totems_pedestal: '',
      res_returned_totems_cover: '',
      res_returned_cards: '',
      res_returned_cards_adm: '',
      res_returned_bracelets: '',
      res_returned_bobines: '',
      res_returned_extension: '',
      res_returned_ruler: '',
      res_returned_kit_cordao: '',
      res_returned_pouch: '',
      res_returned_tablet: '',
      res_returned_tablet_power: '',
      res_returned_pos_power: '',
      res_returned_transport_box: '',
      res_returned_processor_wifi: '',
      res_returned_tripod_wifi: '',
      res_returned_router_wifi: '',
      res_returned_cable_lan: '',

      internet_wifi: '',
      internet_satellite: '',
      // internet_system_wifi: '',

      technical_daily: '',
      technical_extra_hour: '',
      technical_freight: '',
      technical_ticket: '',
      technical_uber: '',
      technical_accommodation: '',
      technical_food: '',

      acquirerReversal: '',
      acquirerReversalDebit: '',
      adquirente: ADQUIRENTE.PAGSEGURO,
      isAdvanceCredit: false,
      typeCharge: TYPE_OF_CHARGE.AUTOMATIC_CHARGE
    },

    validate,
    onSubmit: values => {
      const {
        res_returned_pos,
        res_returned_pos_backup,
        res_returned_totems,
        res_returned_totems_recharges,
        res_returned_totems_cables,
        res_returned_totems_cuffs,
        res_returned_totems_pedestal,
        res_returned_totems_cover,
        res_returned_cards,
        res_returned_cards_adm,
        res_returned_bracelets,
        res_returned_bobines,
        res_returned_extension,
        res_returned_ruler,
        res_returned_kit_cordao,
        res_returned_pouch,
        res_returned_tablet,
        res_returned_tablet_power,
        res_returned_pos_power,
        res_returned_transport_box,
        res_returned_processor_wifi,
        res_returned_tripod_wifi,
        res_returned_router_wifi,
        res_returned_cable_lan,

        internet_wifi,
        internet_satellite,
        // internet_system_wifi,

        technical_daily,
        technical_extra_hour,
        technical_freight,
        technical_ticket,
        technical_uber,
        technical_accommodation,
        technical_food,

        acquirerReversal,
        acquirerReversalDebit,
        adquirente,
        isAdvanceCredit,
        typeCharge
      } = values;

      const data = {
        returned_resources: {
          pos: Number(res_returned_pos),
          pos_backup: Number(res_returned_pos_backup),
          totems: Number(res_returned_totems),
          totems_recharges: Number(res_returned_totems_recharges),
          totems_cables: Number(res_returned_totems_cables),
          totems_cuffs: Number(res_returned_totems_cuffs),
          totems_pedestal: Number(res_returned_totems_pedestal),
          totems_cover: Number(res_returned_totems_cover),
          cards: Number(res_returned_cards),
          cards_adm: Number(res_returned_cards_adm),
          bracelets: Number(res_returned_bracelets),
          bobines: Number(res_returned_bobines),
          extension: Number(res_returned_extension),
          ruler: Number(res_returned_ruler),
          kit_cordao: Number(res_returned_kit_cordao),
          pouch: Number(res_returned_pouch),
          tablet: Number(res_returned_tablet),
          tablet_power: Number(res_returned_tablet_power),
          pos_power: Number(res_returned_pos_power),
          transport_box: Number(res_returned_transport_box),
          processor_wifi: Number(res_returned_processor_wifi),
          tripod_wifi: Number(res_returned_tripod_wifi),
          router_wifi: Number(res_returned_router_wifi),
          cable_lan: Number(res_returned_cable_lan),
        },

        internet: {
          wifi: Number(unmask(internet_wifi)),
          satellite: Number(unmask(internet_satellite)),
          // system_wifi: Number(unmask(internet_system_wifi)),
        },

        technical: {
          daily: Number(unmask(technical_daily)),
          extra_hour: Number(unmask(technical_extra_hour)),
          freight: Number(unmask(technical_freight)),
          ticket: Number(unmask(technical_ticket)),
          uber: Number(unmask(technical_uber)),
          accommodation: Number(unmask(technical_accommodation)),
          food: Number(unmask(technical_food)),
        },

        acquirerReversal: Number(unmask(acquirerReversal)),
        acquirerReversalDebit: Number(unmask(acquirerReversalDebit)),
        adquirente,
        isAdvanceCredit,
        typeCharge: typeCharge ? Number(typeCharge) : TYPE_OF_CHARGE.AUTOMATIC_CHARGE,
      }

      const onSuccessUpdate = () => {
        onShowAlert(PreAlert.success('Registro atualizada com sucesso'))
        setresourceRegistered(true)
      }
      const onError = (error) => {
        console.error(error);
        onShowAlert(PreAlert.error('Falhou ao cadastrar a registro'))
      }

      setIsLoading(true);
      new ClosureResourcesEventDB(estabSelected)
        .define(eventSelected.id, data)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setIsLoading(false))
    }
  });

  const handleOpenRelatorio = useCallback((event) => {
    setOpenRelatorio(true);
  }, [])

  const handleOpenNovoRelatorio = useCallback((event) => {
    setOpenNovoRelatorio(true);
  }, [])

  const handleCloseRelatorio = useCallback(() => {
    setOpenRelatorio(false);
  }, [])
  const handleCloseNovoRelatorio = useCallback(() => {
    setOpenNovoRelatorio(false);
  }, [])


  function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
  }

  const onChangeNumbers = useCallback((e) => inputOnlyNumbers(formik, e), [formik.values])
  const onChangeNumbersNoStitch = useCallback((e) => inputOnlyNumbersNoStitch(formik, e), [formik.values])
  const onChangeMoney = useCallback((e) => inputMoneyMask(formik, e), [formik.values])

  const hoursDifference = useMemo(() => {
    if (!eventSelected || !eventSelected.endDate) return '--';
    const date = eventSelected.date.toDate();
    const endDate = eventSelected.endDate.toDate();
    if (!dateIsValid(date) || !dateIsValid(endDate) || endDate < date)
      return '--';
    return Math.round(Math.abs(date - endDate) / 36e5)
  }, [eventSelected])

  function convertDateToString(date) {
    if (!date)
      return '';
    if (typeof date === 'string')
      return date
    else {
      const eventDate = date.toDate()
      const month = eventDate.getMonth() + 1 > 9 ? eventDate.getMonth() + 1 : '0' + (eventDate.getMonth() + 1)
      const day = eventDate.getDate() > 9 ? eventDate.getDate() : '0' + eventDate.getDate()
      const hours = eventDate.getHours() > 9 ? eventDate.getHours() : '0' + eventDate.getHours()
      const minutes = eventDate.getMinutes() > 9 ? eventDate.getMinutes() : '0' + eventDate.getMinutes()
      return `${eventDate.getFullYear()}-${month}-${day}T${hours}:${minutes}`
    }
  }

  const startTime = useMemo(() => convertDateToString(eventSelected?.date), [eventSelected])
  const endTime = useMemo(() => convertDateToString(eventSelected?.endDate), [eventSelected])

  const adquirenateOptions = useMemo(() => Object.keys(ADQUIRENTE).map(key => ({
    value: ADQUIRENTE[key], label: ADQUIRENTE_LABEL[ADQUIRENTE[key]]
  })), [])
  const typeChargeOptions = useMemo(() => Object.keys(TYPE_OF_CHARGE).map(key => ({
    value: TYPE_OF_CHARGE[key], label: TYPE_OF_CHARGE_LABEL[TYPE_OF_CHARGE[key]]
  })), [])



  return (
    <>
      <Modal
        id={'modal-register-stores'}
        titleId={'Fechamento de Evento'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop
        isCentered
        isAnimation
        size="xl" // 'sm' || 'lg' || 'xl' 
      // onSubmit={formik.handleSubmit}
      >
        <form noValidate onSubmit={formik.handleSubmit}>
          <ModalHeader setIsOpen={setIsOpen}>
            <ModalTitle id="register-stores">Fechamento de Evento</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Nav
              tag='nav'
              isFill
              design='tabs'
            >
              <NavItem
                isActive={tabSelected === 0}
                onClick={() => settabSelected(0)}
              >
                <a>
                  Equipamentos
                </a>
              </NavItem>
              <NavItem
                isActive={tabSelected === 1}
                onClick={() => settabSelected(1)}
              >
                <a>
                  Outros
                </a>
              </NavItem>
            </Nav>
            <div className='row p-4' hidden={tabSelected !== 0}>
              <div className='col-md-4'>
                <h5>Recursos Enviados</h5>
                <div className='row col-md-12 d-flex justify-content-center'>
                  <div className='col-md-7'></div>
                  <div className='col-md-5 d-flex justify-content-center'>QTD</div>
                </div>

                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>POS</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_pos"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pos === undefined ? '' : eventGeral?.resources?.pos}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>POS Reserva</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_pos_backup"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pos_backup === undefined ? '' : eventGeral?.resources?.pos_backup}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Ticket</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_totems"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems === undefined ? '' : eventGeral?.resources?.totems}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Recarga</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_totems_recharges"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_recharges === undefined ? '' : eventGeral?.resources?.totems_recharges}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Cabos</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_totems_cables"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_cables === undefined ? '' : eventGeral?.resources?.totems_cables}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Algemas</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_totems_cuffs"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_cuffs === undefined ? '' : eventGeral?.resources?.totems_cuffs}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Pedestal</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_totems_pedestal"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_pedestal === undefined ? '' : eventGeral?.resources?.totems_pedestal}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Carcaça</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_totems_cover"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_cover === undefined ? '' : eventGeral?.resources?.totems_cover}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cartões</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_cards"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.cards === undefined ? '' : eventGeral?.resources?.cards}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cartões Administrador</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_cards_adm"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.cards_adm === undefined ? '' : eventGeral?.resources?.cards_adm}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Pulseiras</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_bracelets"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.bracelets === undefined ? '' : eventGeral?.resources?.bracelets}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Bobinas</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_bobines"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.bobines === undefined ? '' : eventGeral?.resources?.bobines}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Extensão</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_extension"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.extension === undefined ? '' : eventGeral?.resources?.extension}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Régua</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_ruler"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.ruler === undefined ? '' : eventGeral?.resources?.ruler}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Kit Cordão</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_kit_cordao"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.kit_cordao === undefined ? '' : eventGeral?.resources?.kit_cordao}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Malote</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_pouch"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pouch === undefined ? '' : eventGeral?.resources?.pouch}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Tablet</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_tablet"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.tablet === undefined ? '' : eventGeral?.resources?.tablet}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Carregador Tablet</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_tablet_power"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.tablet_power === undefined ? '' : eventGeral?.resources?.tablet_power}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Carregador POS</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_pos_power"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pos_power === undefined ? '' : eventGeral?.resources?.pos_power}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Caixa Transporte</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_transport_box"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.transport_box === undefined ? '' : eventGeral?.resources?.transport_box}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Switch</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_processor_wifi"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.processor_wifi === undefined ? '' : eventGeral?.resources?.processor_wifi}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Tripé Wi-Fi</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_tripod_wifi"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.tripod_wifi === undefined ? '' : eventGeral?.resources?.tripod_wifi}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Roteador Wi-Fi</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_router_wifi"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.router_wifi === undefined ? '' : eventGeral?.resources?.router_wifi}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cabo LAN</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_sent_cable_lan"
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.cable_lan === undefined ? '' : eventGeral?.resources?.cable_lan}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-4 '>
                <h5>Recursos Devolvidos</h5>
                <div className='row col-md-12 d-flex justify-content-center'>
                  <div className='col-md-7'></div>
                  <div className='col-md-5 d-flex justify-content-center'>QTD</div>
                </div>

                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>POS</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_pos"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_pos}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_pos}
                      invalidFeedback={formik.errors.res_returned_pos}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>POS Reserva</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_pos_backup"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_pos_backup}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_pos_backup}
                      invalidFeedback={formik.errors.res_returned_pos_backup}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Ticket</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_totems"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_totems}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_totems}
                      invalidFeedback={formik.errors.res_returned_totems}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Recarga</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_totems_recharges"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_totems_recharges}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_totems_recharges}
                      invalidFeedback={formik.errors.res_returned_totems_recharges}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Cabos</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_totems_cables"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_totems_cables}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_totems_cables}
                      invalidFeedback={formik.errors.res_returned_totems_cables}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Algemas</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_totems_cuffs"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_totems_cuffs}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_totems_cuffs}
                      invalidFeedback={formik.errors.res_returned_totems_cuffs}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Pedestal</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_totems_pedestal"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_totems_pedestal}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_totems_pedestal}
                      invalidFeedback={formik.errors.res_returned_totems_pedestal}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Carcaça</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_totems_cover"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_totems_cover}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_totems_cover}
                      invalidFeedback={formik.errors.res_returned_totems_cover}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cartões</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_cards"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_cards}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_cards}
                      invalidFeedback={formik.errors.res_returned_cards}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cartões Administrador</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_cards_adm"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_cards_adm}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_cards_adm}
                      invalidFeedback={formik.errors.res_returned_cards_adm}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Pulseiras</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_bracelets"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_bracelets}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_bracelets}
                      invalidFeedback={formik.errors.res_returned_bracelets}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Bobinas</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_bobines"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_bobines}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_bobines}
                      invalidFeedback={formik.errors.res_returned_bobines}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Extensão</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_extension"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_extension}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_extension}
                      invalidFeedback={formik.errors.res_returned_extension}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Régua</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_ruler"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_ruler}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_ruler}
                      invalidFeedback={formik.errors.res_returned_ruler}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Kit Cordão</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_kit_cordao"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_kit_cordao}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_kit_cordao}
                      invalidFeedback={formik.errors.res_returned_kit_cordao}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Malote</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_pouch"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_pouch}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_pouch}
                      invalidFeedback={formik.errors.res_returned_pouch}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Tablet</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_tablet"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_tablet}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_tablet}
                      invalidFeedback={formik.errors.res_returned_tablet}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Carregador Tablet</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_tablet_power"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_tablet_power}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_tablet_power}
                      invalidFeedback={formik.errors.res_returned_tablet_power}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Carregador POS</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_pos_power"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_pos_power}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_pos_power}
                      invalidFeedback={formik.errors.res_returned_pos_power}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Caixa Transporte</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_transport_box"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_transport_box}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_transport_box}
                      invalidFeedback={formik.errors.res_returned_transport_box}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Switch</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_processor_wifi"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_processor_wifi}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_processor_wifi}
                      invalidFeedback={formik.errors.res_returned_processor_wifi}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Tripé Wi-Fi</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_tripod_wifi"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_tripod_wifi}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_tripod_wifi}
                      invalidFeedback={formik.errors.res_returned_tripod_wifi}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Roteador Wi-Fi</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_router_wifi"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_router_wifi}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_router_wifi}
                      invalidFeedback={formik.errors.res_returned_router_wifi}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cabo LAN</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="res_returned_cable_lan"
                      onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={formik.values.res_returned_cable_lan}
                      isValid={formik.isValid}
                      isTouched={formik.touched.res_returned_cable_lan}
                      invalidFeedback={formik.errors.res_returned_cable_lan}
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <h5>Recursos Faltantes</h5>
                <div className='row col-md-12 d-flex justify-content-center'>
                  <div className='col-md-7'></div>
                  <div className='col-md-5 d-flex justify-content-center'>QTD</div>
                </div>

                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>POS</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pos - Number(formik.values.res_returned_pos)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>POS Reserva</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pos_backup - Number(formik.values.res_returned_pos_backup)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems - Number(formik.values.res_returned_totems)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Recarga</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_recharges - Number(formik.values.res_returned_totems_recharges)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Cabos</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_cables - Number(formik.values.res_returned_totems_cables)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Algemas</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_cuffs - Number(formik.values.res_returned_totems_cuffs)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Pedestal</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_pedestal - Number(formik.values.res_returned_totems_pedestal)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Totem Carcaça</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.totems_cover - Number(formik.values.res_returned_totems_cover)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cartões</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.cards - Number(formik.values.res_returned_cards)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cartões Administrador</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.cards_adm - Number(formik.values.res_returned_cards_adm)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Pulseiras</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.bracelets - Number(formik.values.res_returned_bracelets)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Bobinas</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.bobines - Number(formik.values.res_returned_bobines)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Extensão</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.extension - Number(formik.values.res_returned_extension)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Régua</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.ruler - Number(formik.values.res_returned_ruler)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Kit Cordão</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.kit_cordao - Number(formik.values.res_returned_kit_cordao)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>

                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Malote</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pouch - Number(formik.values.res_returned_pouch)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Tablet</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.tablet - Number(formik.values.res_returned_tablet)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Carregador Tablet</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.tablet_power - Number(formik.values.res_returned_tablet_power)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Carregador POS</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.pos_power - Number(formik.values.res_returned_pos_power)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Caixa Transporte</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.transport_box - Number(formik.values.res_returned_transport_box)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Switch</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.processor_wifi - Number(formik.values.res_returned_processor_wifi)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Tripé Wi-Fi</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.tripod_wifi - Number(formik.values.res_returned_tripod_wifi)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Roteador Wi-Fi</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.router_wifi - Number(formik.values.res_returned_router_wifi)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Cabo LAN</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      // onChange={onChangeNumbers}
                      onBlur={formik.handleBlur}
                      value={eventGeral?.resources?.cable_lan - Number(formik.values.res_returned_cable_lan)}
                      readOnly
                      placeholder="Ex: 0"
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className='row p-4' hidden={tabSelected !== 1}>
              <div className='col-md-4 mt-4'>
                <h5>Técnicos/Coordenadores</h5>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Qtd. Diária</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_daily"
                      onChange={onChangeNumbersNoStitch}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_daily}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_daily}
                      invalidFeedback={formik.errors.technical_daily}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Qtd. Horas Extra</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_extra_hour"
                      onChange={onChangeNumbersNoStitch}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_extra_hour}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_extra_hour}
                      invalidFeedback={formik.errors.technical_extra_hour}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Frete</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_freight"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_freight}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_freight}
                      invalidFeedback={formik.errors.technical_freight}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Passagens</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_ticket"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_ticket}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_ticket}
                      invalidFeedback={formik.errors.technical_ticket}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Uber</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_uber"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_uber}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_uber}
                      invalidFeedback={formik.errors.technical_uber}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Hospedagem</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_accommodation"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_accommodation}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_accommodation}
                      invalidFeedback={formik.errors.technical_accommodation}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Alimentação</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="technical_food"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.technical_food}
                      isValid={formik.isValid}
                      isTouched={formik.touched.technical_food}
                      invalidFeedback={formik.errors.technical_food}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
              </div>

              <div className='col-md-4 mt-4'>
                <h5>Internet</h5>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>WIFI</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="internet_wifi"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.internet_wifi}
                      isValid={formik.isValid}
                      isTouched={formik.touched.internet_wifi}
                      invalidFeedback={formik.errors.internet_wifi}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-7'>
                    <Label>Satélite</Label>
                  </div>
                  <div className='col-md-5'>
                    <Input
                      id="internet_satellite"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.internet_satellite}
                      isValid={formik.isValid}
                      isTouched={formik.touched.internet_satellite}
                      invalidFeedback={formik.errors.internet_satellite}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </div>
                </div>
                {/* <div className='row col-md-12 align-items-center mt-2'>
                <div className='col-md-4'>
                  <Label>Sistema WIFI</Label>
                </div>
                <div className='col-md-6'>
                  <Input
                    id="internet_system_wifi"
                    onChange={onChangeMoney}
                    onBlur={formik.handleBlur}
                    value={formik.values.internet_system_wifi}
                    isValid={formik.isValid}
                    isTouched={formik.touched.internet_system_wifi}
                    invalidFeedback={formik.errors.internet_system_wifi}
                    // validFeedback='Assim está bom!'
                    placeholder="Ex: 0,00"
                  />
                </div>
              </div> */}
              </div>

              <div className='col-md-4 mt-4'>
                <h5>Estornos Adquirência</h5>
                <div className='row col-md-12 align-items-center mt-2'>
                  {/* <div className='col-md-4'>
                  <Label>Sistema WIFI</Label>
                </div> */}
                  <FormGroup className='col-md-10' label='Estornos Crédito'>
                    <Input
                      id="acquirerReversal"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.acquirerReversal}
                      isValid={formik.isValid}
                      isTouched={formik.touched.acquirerReversal}
                      invalidFeedback={formik.errors.acquirerReversal}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </FormGroup>
                  <FormGroup className='col-md-10 mt-2' label='Estornos Débito'>
                    <Input
                      id="acquirerReversalDebit"
                      onChange={onChangeMoney}
                      onBlur={formik.handleBlur}
                      value={formik.values.acquirerReversalDebit}
                      isValid={formik.isValid}
                      isTouched={formik.touched.acquirerReversalDebit}
                      invalidFeedback={formik.errors.acquirerReversalDebit}
                      // validFeedback='Assim está bom!'
                      placeholder="Ex: 0,00"
                    />
                  </FormGroup>
                </div>
                <h5 className='mt-4'>Outros</h5>
                <div className='row col-md-12 align-items-center mt-2'>
                  <FormGroup className='col-md-10' label='Antecipar Adquirência'>
                    <Checks
                      type="switch"
                      id="isAdvanceCredit"
                      name="isAdvanceCredit"
                      label={formik.values.isAdvanceCredit ? 'Taxa D+1' : 'Taxa D+30'}
                      checked={formik.values.isAdvanceCredit}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <FormGroup id='adquirente' label="Adquirente" className='col-md-6'>
                    <Select
                      value={formik.values.adquirente}
                      list={adquirenateOptions}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </div>
                <h5 className='mt-4'>Cobrança</h5>
                <div className='row col-md-12 mt-2'>
                  <FormGroup id='typeCharge' label="Tipo de cobrança" className='col-md-10'>
                    <Select
                      value={formik.values.typeCharge}
                      list={typeChargeOptions}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className='col-md-6 mt-4'>
                <h5>Hórarios</h5>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-4'>
                    <Label>Inicio</Label>
                  </div>
                  <div className='col-md-8'>
                    <Input
                      id="startTime"
                      type='datetime-local'
                      // onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={startTime}
                      readOnly
                      placeholder="Ex: 100"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-4'>
                    <Label>Termino</Label>
                  </div>
                  <div className='col-md-8'>
                    <Input
                      id="endTime"
                      type='datetime-local'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={endTime}
                      readOnly
                      placeholder="Ex: 100"
                    />
                  </div>
                </div>
                <div className='row col-md-12 align-items-center mt-2'>
                  <div className='col-md-4'>
                    <Label>Horas Trabalhadas</Label>
                  </div>
                  <div className='col-md-8'>
                    <Input
                      id="hoursDifference"
                      readOnly
                      value={hoursDifference + ' H'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className='col-md-12 d-flex justify-content-end mt-4'>
              <Button
                className='mx-4 text-dark'
                disabled={!eventGeral || !resourceRegistered}
                type='button'
                color='warning'
                icon="PictureAsPdf"
                rounded={1}
                hoverShadow="sm"
                shadow="sm"
                size="sm"
                onClick={handleOpenRelatorio}
              >
                Relatório
              </Button>
              <Button
                className='mx-4 text-dark'
                disabled={!eventGeral || !resourceRegistered}
                type='button'
                color='secondary'
                icon="PictureAsPdf"
                rounded={1}
                hoverShadow="sm"
                shadow="sm"
                size="sm"
                onClick={handleOpenNovoRelatorio}
              >
                Novo Relatório
              </Button>
              {isLoading ? (
                <BtnLoad />
              ) : (
                <BtnConfirm
                  isDisable={!formik.isValid}
                  label='Salvar'
                />
              )}
            </div>

          </ModalFooter>
        </form>
      </Modal >
      <Relatorio
        open={openRelatorio}
        setIsOpen={setOpenRelatorio}
        setIsClose={handleCloseRelatorio}
        eventSelected={eventSelected}
      />
      <NovoRelatorio
        open={openNovoRelatorio}
        setIsOpen={setOpenNovoRelatorio}
        setIsClose={handleCloseNovoRelatorio}
        eventSelected={eventSelected}
      />
    </>
  )
}

export default memo(ModalClosure);