import React, { useMemo } from "react";
import Money from "../../../helpers/utils/money/money";
import { TYPE_OF_CHARGE, TYPE_OF_CHARGE_LABEL } from "../../../types/charge";

const SystemFeeTable = ({ resume, eventGeral, closureResourcesEvent, faturamento, movimentacao, typeOfChargeApplied, equipaments, typeOfCharge }) => {

  const pos = useMemo(() => {
    if (!equipaments) return {};
    const posObj = equipaments.find((e) => e.key === 'pos');
    return posObj;
  }, [equipaments]);

  if (!faturamento || !movimentacao || !eventGeral || !closureResourcesEvent) return null;

  return (
    <div className="" >
      <style>
        {`
          @media print{
            .no-print {
              display: none;
            }
          }
        ` }
      </style>
      <h4 className='text-center p-4 fw-bold mb-5 pagebreak'>VALORES REFERENTES AS TAXAS E LICENÇAS DO SISTEMA</h4>


      <p className="no-print">CRITÉRIO: {TYPE_OF_CHARGE_LABEL[typeOfChargeApplied]}</p>

      <table className='table table-striped table-fs-litle'>
        <tbody>
          <tr>
            <td>TOTAL MOVIMENTADO EM DINHEIRO</td>
            <td></td>
            <td>{Money.centsToMaskMoney(faturamento.cash)}</td>
            <td>TAXA %: {eventGeral.rate.cash}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.cash)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL INTERMEDIADO EM DÉBITO</td>
            <td>
              {closureResourcesEvent.acquirerReversalDebit > 0 ?
                `ESTORNO: ${Money.centsToMaskMoney(closureResourcesEvent.acquirerReversalDebit)}`
                :
                ''
              }
            </td>
            <td>{Money.centsToMaskMoney(faturamento.debit)}</td>
            <td>TAXA %: {eventGeral.rate.debit}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.debit)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL INTERMEDIADO EM CRÉDITO</td>
            <td>
              {closureResourcesEvent.acquirerReversal > 0 ?
                `ESTORNO: ${Money.centsToMaskMoney(closureResourcesEvent.acquirerReversal)}`
                :
                ''
              }
            </td>
            <td>{Money.centsToMaskMoney(faturamento.credit)}</td>
            <td>TAXA %: {eventGeral.rate.credit}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.credit)}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>TOTAL INTERMEDIADO EM PIX</td>
            <td></td>
            <td>{Money.centsToMaskMoney(faturamento.pix)}</td>
            <td>TAXA %: {eventGeral.rate.pix}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.pix)}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td>TOTAL MOVIMENTADO EM CORTESIA</td>
            <td></td>
            <td>{Money.centsToMaskMoney(faturamento.courtesy)}</td>
            <td>TAXA %: {eventGeral.rate.courtesy}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.courtesy)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TOTAL MOVIMENTADO EM RECARGA ANTECIPADA</td>
            <td></td>
            <td>{Money.centsToMaskMoney(movimentacao.recargaAntecipada)}</td>
            <td>TAXA %: {eventGeral.rate.advance_recharge}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.advanced_recharge)}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>TAXA REFERENTE A ANTECIPAÇÃO DO VALOR TRANSACIONADO EM CRÉDITO</td>
            <td></td>
            <td>{Money.centsToMaskMoney(faturamento.credit)}</td>
            <td>TAXA %: {eventGeral.rate.advance_credit}</td>
            <td>
              <div className="d-flex justify-content-between">
                <div>
                  VALOR REFERENTE A TAXA:
                </div>
                <div  style={{ whiteSpace: 'nowrap' }}>
                  {Money.centsToMaskMoney(resume.feeValues.advanced_credit)}
                </div>
              </div>
            </td>
          </tr>

          <tr className="fw-bold">
            <td colSpan='4'>VALOR REFERENTE A TAXA uGet ;-) SISTEMA E INTERMEDIAÇÃO</td>
            <td className="text-tb-align-right"  style={{ whiteSpace: 'nowrap' }}>{Money.centsToMaskMoney(resume.tax_uget)}</td>
          </tr>
          {typeOfCharge === TYPE_OF_CHARGE.AUTOMATIC_CHARGE && typeOfChargeApplied !== TYPE_OF_CHARGE.PAYMENT_RATE_CHARGE &&
            <tr className="fw-bold">
              <td colSpan='1'>DEVIDO O VALOR DA TAXA uGet ;-) NÃO SUPERAR O VALOR MINIMO, SERÁ COBRADO O VALOR REFERENTE A LICENÇA DOS {pos.qtdSent} TERMINAIS</td>
              <td colSpan='3' >NO VALOR UNÍTÁRIO: {Money.centsToMaskMoney(pos.value || 0)}</td>
              <td>
                <div className="d-flex justify-content-between">
                  <div>
                    TOTALIZANDO O VALOR DE:
                  </div>
                  <div  style={{ whiteSpace: 'nowrap' }}>
                    {Money.centsToMaskMoney(pos?.total_rent || 0)}
                  </div>
                </div>
              </td>
            </tr>
          }

          {typeOfChargeApplied === TYPE_OF_CHARGE.TOTAL_CHARGE &&

            <tr className="fw-bold">
              <td colSpan='4'>VALORES REFERENTES A POS EM COMODATO</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(pos?.total_rent)}</td>
            </tr>}

          {typeOfChargeApplied === TYPE_OF_CHARGE.TOTAL_CHARGE &&

            <tr className="fw-bold">
              <td colSpan='4'>TOTAL</td>
              <td className="text-tb-align-right">{Money.centsToMaskMoney(pos?.total_rent + resume.tax_uget)}</td>
            </tr>}
        </tbody>
      </table>
    </div>
  );
}

export default SystemFeeTable;