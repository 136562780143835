import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { DefaultContext } from '../../../contexts/default';
import ClosureResourcesEventDB from '../../../database/wrappers/closureResourceEvent';
import PreAlert from '../../../helpers/utils/preAlert';
import useDarkMode from '../../../hooks/useDarkMode';
import { closureCashiersAndTotems } from '../../../utils/closureEvent';
import BtnCancel from '../../MyCustom/BtnCancel';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalFooter } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import api from '../../../services/api';
import AdditionalServices from '../../MyCustom/ClosureEventCustom/additionalServices';
import ClosureCashierTable from '../../MyCustom/ClosureEventCustom/closureCashiersTable';
import EquipamentTable from '../../MyCustom/ClosureEventCustom/equipments';
import FirstPage from '../../MyCustom/ClosureEventCustom/firtsEvent';
import HeaderFirstPage from '../../MyCustom/ClosureEventCustom/headerFirstPage';
import LastPage from '../../MyCustom/ClosureEventCustom/lastPage';
import SystemFeeTable from '../../MyCustom/ClosureEventCustom/systemFeeTable';
import TransferTable from '../../MyCustom/ClosureEventCustom/transferTable';
import './styles.css';
import LossDamage from '../../MyCustom/ClosureEventCustom/perdasDanos';
import { TYPE_OF_CHARGE } from '../../../types/charge';
import { PAYMENT_TYPE } from '../../../types/payment';

const NovoRelatorio = ({ open, setIsOpen, setIsClose, eventSelected }) => {
  const { darkModeStatus } = useDarkMode();

  const { establishment, estabSelected, onShowAlert } = useContext(DefaultContext);

  const [loading, setloading] = useState(false)
  const [result, setresult] = useState(null)
  const [closureCashiers, setclosureCashiers] = useState([])
  const [resourcesEvent, setresourcesEvent] = useState(null)
  
  useEffect(() => {
    if (open && !eventSelected && !estabSelected) return;
    new ClosureResourcesEventDB(estabSelected)
      .get(eventSelected?.id)
      .then(data => {
        if (!data) return
        setresourcesEvent(data);

      })
      .catch(error => console.error(error))
  }, [open, estabSelected, eventSelected])

  useEffect(() => {
    if (open && !!eventSelected) {
      async function loadData() {
        try {
          setloading(true);
          const params = {
            estabId: estabSelected,
            eventId: eventSelected.id,
          }
          const res = await api.get('routes/admContabil/new?' + new URLSearchParams(params).toString())
          const closures = await closureCashiersAndTotems(estabSelected, eventSelected.id)
          setresult(res.data)
          setclosureCashiers(closures)
        } catch (error) {
          console.error(error);
          setIsOpen(false)
          onShowAlert(PreAlert.error(error.response?.data?.message || 'Ocorreu um erro imprevisto!'))
        } finally {
          setloading(false)
        }
      }

      loadData();
    } else {
      setresult(null)
    }
  }, [open, eventSelected])

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Fechamento - ' + eventSelected?.name,

  });


  const LoadSpinner = () => (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <Spinner
        size={80}
        color={darkModeStatus ? 'light' : 'dark'}
      />
    </div>
  )
  return (
    <Modal
      id={'modal-register-stores'}
      titleId={'Cadastro de Estabelecimentos'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="xl" // 'sm' || 'lg' || 'xl' 
      isAnimation={true}
      fullScreen
    >
      <ModalBody ref={componentRef} >
        <HeaderFirstPage eventSelected={eventSelected} establishment={establishment} />
        {loading ?
          <LoadSpinner />
          :
          <>
            <FirstPage eventSelected={eventSelected}
              establishment={establishment}
              eventGeral={result?.eventGeral}
              closureResourcesEvent={result?.closureEvent}
              equipaments={result?.equipaments} 
            />
            <EquipamentTable equipaments={result?.equipaments} equipamentsValues={result?.equipamentsValues} />
            <LossDamage equipaments={result?.equipaments} equipamentsValues={result?.equipamentsValues} />
            <ClosureCashierTable closureCashiers={closureCashiers} />

            {result?.typeCharge !== TYPE_OF_CHARGE.RENT_CHARGE &&
              <SystemFeeTable
                resume={result?.resume}
                eventGeral={result?.eventGeral}
                closureResourcesEvent={result?.closureEvent}
                faturamento={result?.faturamento}
                movimentacao={result?.movimentacao}
                typeOfChargeApplied={result?.typeOfChargeApplied}
                equipaments={result?.equipaments}
                typeOfCharge={result?.typeCharge}
              />
            }
            <AdditionalServices 
              typeOfChargeApplied={result?.typeOfChargeApplied}
              equipaments={result?.equipaments}
              closureResourcesEvent={result?.closureEvent}
              custoOperacional={result?.configUget.custoOperacional}
              responsibles={result?.responsibles}
              technical={result?.technical}
              resume={result?.resume}
              eventGeral={result?.eventGeral}
            />
            <TransferTable
              establishment={establishment}
              resume={result?.resume}
              eventGeral={result?.eventGeral}
              closureResourcesEvent={result?.closureEvent}
              faturamento={result?.faturamento}
              movimentacao={result?.movimentacao}
              equipaments={result?.equipaments}
              typeOfChargeApplied={result?.typeOfChargeApplied}
            />

            <LastPage establishment={establishment} eventSelected={eventSelected} />
          </>
        }
      </ModalBody>

      <ModalFooter>
        <div className='row col-md-12 justify-content-around'>
          <BtnCancel
            className='col-md-3'
            setIsClose={setIsClose}
          />
          <Button onClick={handlePrint} className='col-md-3' type='button' color='primary' icon="PictureAsPdf" rounded={1} hoverShadow="sm" shadow="sm" size="sm">
            Imprimir ou Baixar
          </Button>
        </div>
      </ModalFooter>
    </Modal >
  )
}

export default memo(NovoRelatorio);