import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import Card, { CardBody, CardHeader, CardLabel, CardTitle, CardActions } from '../../../../components/bootstrap/Card';
import Chart from '../../../../components/extras/Chart';
import { DefaultContext } from '../../../../contexts/default';
import DashGraficoPorHora from '../../../../database/wrappers/dashboard/graficoPorHora';
import useDarkMode from '../../../../hooks/useDarkMode';
import { computeSexoPorHora } from '../../../../utils/dashboard/porHora';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import EntranceDB from '../../../../database/wrappers/entrance';
import { POR_HORA_CHAR_OPTIONS_NO_MONEY } from '../../../financial/utils/charOptionsWithoutMoney';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import { demoPages } from '../../../../menu';
import Page from '../../../../layout/Page/Page';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectEvent from '../../../../components/MyCustom/SelectEvent';
import { POR_HORA_CHAR_OPTIONS } from '../../../financial/utils/charOptions';
import EntrancePayEventData from '../../../../database/wrappers/eventData/entrancePay';
import { SEX } from '../../../../types/sex';

const BoxOfficeTime = () => {
  const { darkModeStatus } = useDarkMode();
  const { estabSelected, eventSelected } = useContext(DefaultContext);
  const [entrancesDicionary, setEntrancesDicionary] = useState([]);
  const [entrancePay, setEntrancePay] = useState([]);
  
  useEffect(() => {
    console.log(estabSelected, eventSelected);
    if(!estabSelected || !eventSelected) return;
    const fetchEntrances = async () => {
      try {
        const entrances = await new EntranceDB(estabSelected, eventSelected).getAllDicionary();
        setEntrancesDicionary(entrances)
      } catch (error) {
        console.error('Erro ao obter dados:', error);
      }
    };
    fetchEntrances();
  }, [estabSelected, eventSelected]);

  useEffect(() => {
    if (estabSelected && eventSelected) {
      const onSubscriber = new EntrancePayEventData(estabSelected, eventSelected).on(
        setEntrancePay,
      )
      return () => {
        onSubscriber();
        setEntrancePay([])
      }
    }
  }, [estabSelected, eventSelected])





  const differenceHour = useMemo(() => {
    const d = new Date();
    return d.getHours() - d.getUTCHours();
  }, []);

  const entrancesByHour = useMemo(() => {
    const groupedEntrances = [];
    if(!entrancesDicionary || !entrancePay) return [];
    entrancePay?.forEach((ent) => {
      const date = ent.created_at?.toDate()?.getUTCHours() - 3;
      const hour = ((date + 24) % 24).toString();
      ent.entrances?.forEach((e) => {
      
        const sex = entrancesDicionary ? entrancesDicionary[e?.id]?.sex ?? SEX.INDEFINIDO.toString() : SEX.INDEFINIDO.toString();

        const price =( e.price ?? 0) * (e.qtd ?? 1);
        const existingGroup = groupedEntrances.find(
          (entry) => entry.sex === sex && entry.hour === hour
        );
    
        if (existingGroup) {
          existingGroup.qtd += e.qtd;
          existingGroup.price += price;
        } else {
          groupedEntrances.push({
            sex,
            hour,
            qtd: e.qtd,
            price,
          });
        }
      });
    })
    
    return groupedEntrances;
  }, [entrancePay, entrancesDicionary]);
  

  const sexoHoras = useMemo(() => computeSexoPorHora(entrancesByHour, differenceHour, true), [entrancesByHour])
  const sexoHorasByPrice = useMemo(() => computeSexoPorHora(entrancesByHour, differenceHour, false), [entrancesByHour])

  const DUMMY_DATA = {
    series: [
      {
        name: 'Masculino',
        data: sexoHoras.arrayMasculino,
      },
      {
        name: 'Feminino',
        data: sexoHoras.arrayFeminino,
      },
      {
        name: 'Indefinido',
        data: sexoHoras.arrayIndefinido,
      },
    ],
    options: {
      ...POR_HORA_CHAR_OPTIONS_NO_MONEY,
      xaxis: {
        categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      },
    },
  }

  const DUMMY_PRICE = {
    series: [
      {
        name: 'Masculino',
        data: sexoHorasByPrice.arrayMasculino,
      },
      {
        name: 'Feminino',
        data: sexoHorasByPrice.arrayFeminino,
      },
      {
        name: 'Indefinido',
        data: sexoHorasByPrice.arrayIndefinido,
      },
    ],
    options: {
      ...POR_HORA_CHAR_OPTIONS,
      xaxis: {
        categories: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      },
    },
  }


  return (

    <PageWrapper title={demoPages.reports.subMenu.boxOfficeTime.text}>
      <SubHeader>
        <SubHeaderLeft>
          <Breadcrumb
            list={[
              { title: 'Relatórios', to: '/reports' },
              { title: 'Entradas por hora', to: '/reports/boxOfficeTime' },
            ]}
          />
        </SubHeaderLeft>
      </SubHeader>

      <Page container="fluid">
        
          <Card stretch>
            <CardHeader>
              <CardLabel icon='Timeline' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                <CardTitle className='d-flex flex-row align-items-center'>
                  <CardActions>QTD BILHETERIA POR HORA</CardActions>
                  <Popovers trigger={'hover'} desc={<b>Bilheteria por hora</b>}>
                    <Icon className='mx-3' size='2x' icon='Info' />
                  </Popovers>
                </CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody>
              <div className='row'>
                <SelectEstablishment />
                <SelectEvent />
              </div>
              <Chart
                series={DUMMY_DATA.series}
                options={DUMMY_DATA.options}
                type={DUMMY_DATA.options.chart.type}
                height={DUMMY_DATA.options.chart.height}
              />
            </CardBody>
          </Card>

          <Card stretch>
            <CardHeader>
              <CardLabel icon='Timeline' iconColor={!darkModeStatus ? 'dark' : 'light'}>
                <CardTitle className='d-flex flex-row align-items-center'>
                  <CardActions>PREÇO BILHETERIA POR HORA</CardActions>
                  <Popovers trigger={'hover'} desc={<b>Bilheteria por hora</b>}>
                    <Icon className='mx-3' size='2x' icon='Info' />
                  </Popovers>
                </CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody>
              <Chart
                series={DUMMY_PRICE.series}
                options={DUMMY_PRICE.options}
                type={DUMMY_PRICE.options.chart.type}
                height={DUMMY_PRICE.options.chart.height}
                />
            </CardBody>
          </Card>
       
      </Page>
    </PageWrapper>
  );
};

export default memo(BoxOfficeTime);
